/* Schaltflächen */

button {
    cursor: pointer;
    background-color: rgba(51,255,51,.7);
    color: black;
    padding: 4px;
    border: 0;
    border-radius: 6px;
    height: 36px;
    margin-top: 4px;
    padding-left: 10px;
    padding-right: 10px;
    opacity: 0.7;
    font-weight: bold;
}
button:hover:not(:disabled) {
    opacity: 1;
    background-color: rgba(51,255,51,1);
}
button:disabled {
    opacity: .2;
}
button.secondary {
    background-color: transparent;
    color: white;
    border: 2px solid rgba(51,255,51,.7);
}
button.secondary:hover:not(:disabled) {
    opacity: 1;
    color: black;
    border: 2px solid rgba(51,255,51,1);
}

.ButtonSmall {
    background-color: transparent;
    color: currentColor;
    border: 0;
    margin-top: 0;
}
.ButtonSmall:hover:not(:disabled) {
    opacity: 1;
    color: black;
    border: 2px solid rgba(51,255,51,1);
}
.PageActions {
    position: relative;
    margin-bottom: 40px;
}
.PageActions * {
    float: right;
    margin-left: 10px;
    margin-bottom: 5px;
}

/* Texteingabe */

.TextInput {
    white-space: nowrap;
    box-sizing: border-box;
    display: block;
    min-width: 300px;
    max-width: 700px;
    width: 100%;
    padding: 10px 10px;
    color: currentColor;
    background: transparent;
    border-radius: 5px;
    border: 1px solid currentColor;
    margin-top: 4px;
    margin-bottom: 6px;
    outline: none;
}
.TextInput:focus {
    border: 3px solid currentColor;
    outline: none;
    margin-top: 2px;
    margin-bottom: -4px;
}
.table-number {
    text-align: right;
    padding-right: 5px;
}

.LoginInput {
    width: 250px;
    outline: none;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom: 1px solid currentColor;
    color: currentColor;
    background: transparent;
}

.PasswordInput {

    width: 250px;
    outline: none;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom: 1px solid currentColor;
    color: currentColor;
    background: transparent;
}